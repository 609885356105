export const blocks = require('./blocks.svg');
export const brush = require('./brush.svg');
export const coffeeBreaks = require('./coffee-breaks.svg');
export const cog = require('./cog.svg');
export const dave = require('./dave.png');
export const desk = require('./desk.svg');
export const email = require('./email.svg');
export const instagram = require('./insta.svg');
export const jump = require('./jump.svg');
export const next = require('./next.svg');
export const starburst = require('./starburst.svg');
export const twitter = require('./twitter.svg');
export const whiteboard = require('./whiteboard.svg');
export const logo = require('./dave-logo.svg');
export const name = require('./dave-name.svg');
export const code = require('./code.svg');
export const computer = require('./computer.svg');
export const animation = require('./animation.svg');
export const camera = require('./photo-camera.svg');
export const notebook = require('./notebook.svg');