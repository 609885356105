import React from 'react';
import Bleed from './Bleed';
import BrandLogos from './BrandLogos';
import SkillsSpinner from '../SkillsWidget2/SkillsWidget2';
import './Homepage.scss';
import { twitter, instagram, email, dave, desk, logo } from '../images';

const Hero = () => {
  console.log(twitter)
  return (
    <div className="homepage-hero">
      <div className="homepage-title">
        <h1>
          Dave Cruikshank <span>Design &amp; Development</span>
        </h1>
      </div>
      <div className="socials">
        <a className="social-link" href="https://twitter.com/davemotron" target="_blank" rel="noopener noreferrer">
            <img src={twitter.default} alt="Twitter"></img>
          </a>
        <a className="social-link" href="https://www.instagram.com/davemotron/" target="_blank" rel="noopener noreferrer">
            <img src={instagram.default} alt="Instagram"></img>
          </a>
        <a className="social-link" href="mailto:why@hellodave.com.au" target="_blank" rel="noopener noreferrer">
          <img src={email.default} alt="Email"></img>
        </a>
      </div>
    </div>
  )
};

const WhoIAm = () => {
  return (
    <div className="who-i-am homepage-section">    
      <div className="dave-img">
        <div className="container">
        <div className="container--inner">
          <div className='circle'></div>
          <img className="img dave-illustration" alt="Dave Cruikshank" src={dave.default} />
        </div>
        </div>
      </div>
      <h2>Hi there!</h2>
      <p>My name is <strong>Dave Cruikshank</strong>, from Sydney, Australia.</p>
      <p>I've been a <strong>web designer &amp; developer</strong> for more than 10 years.
      I love art &amp; design, am a bit of a tech nerd, and have an
      unhealthy relationship with black coffee.</p>
      <Bleed position="bottom" colour="blue"></Bleed>
    </div>
  )
};

const WhatIDo = () => {
  return (
    <div className="what-i-do homepage-section">
      <h2>What I do</h2>
      <p>I design &amp; build <strong>beautiful, user-focused websites</strong> that communicate effectively and excitingly online. Using the latest technology, with a focus on compatibility, I can craft innovative design solutions for your business.</p>
      <img className="desk-img" src={desk.default} alt="Desk" />
      <Bleed position="top" colour="red"></Bleed>
      <Bleed position="bottom" colour="green"></Bleed>
    </div>
  )
};

const Skills = () => {
  return (
    <div className="skills homepage-section">
      <h2>How I do it</h2>
      <SkillsSpinner></SkillsSpinner>
      <Bleed position="top" colour="blue"></Bleed>
      <Bleed position="bottom" colour="orange"></Bleed>
    </div>
  )
};

const Brands = () => {
  return (
    <div className="brands container homepage-section">
      <h2>Brands I've worked with</h2>
      <BrandLogos />
      <Bleed position="top" colour="green"></Bleed>
      <Bleed position="bottom" colour="white"></Bleed>
    </div>
  )
}

const SayHi = () => {
  return (
    <div className="say-hi homepage-section">
      <img src={logo} className="site-logo" alt="dc logo" />
      <h2>Don't be shy,<br/> come say hi!</h2>
      <div className="socials">
        <a className="social-link" href="https://twitter.com/davemotron" target="_blank" rel="noopener noreferrer">
          <img src={twitter.default} alt="Twitter"></img>
        </a>
        <a className="social-link" href="https://www.instagram.com/davemotron/" target="_blank" rel="noopener noreferrer">
          <img src={instagram.default} alt="Instagram"></img>
        </a>
        <a className="social-link" href="mailto:why@hellodave.com.au" target="_blank" rel="noopener noreferrer">
          <img src={email.default} alt="Email"></img>
        </a>
      </div>
      <Bleed position="top" colour="orange"></Bleed>
    </div>
  )
};

export {
  Hero,
  WhoIAm,
  WhatIDo,
  Skills,
  Brands,
  SayHi,
}