import React from 'react';
import './SkillsWidget2.scss';
import { code, computer, animation, camera, notebook } from '../images';

class SkillSpinner extends React.Component {
  state = {
    isMobile: false
  };

  componentDidMount() {
    this.isTouchDevice();
    this.initSkills();
  };

  isTouchDevice = () => {
    this.setState({isMobile: (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0))});
  
  }

  initSkills = () => {
    var skillsRows = document.querySelectorAll("#SkillsContainer .row");
    const interaction = this.state.isMobile ? 'touchend' : 'mouseover';
    skillsRows.forEach((skill) => {
      skill.addEventListener(interaction, (e) => {
        if(e.target.classList.contains('active')) {
          return false;
        } else {
          document.querySelector('#SkillsContainer .row.active').classList.remove('active');
          e.target.classList.add('active');
        }
      });
    });
  };

  render() {
    return (
      <div id="SkillsContainer" data-mobile={this.state.isMobile}>
        <div className="row active">
          <img src={code.default} alt="code" />
          <div className="text">
            <h3>Web Development</h3>
            <p>For the past decade I have developed websites and applications for a wide range of clients, from large multinationals to non-profits and start-ups.</p>
            <p className="desktop-only">I am fluent in many modern frontend languages and always create websites with UX and accessibility in front of mind.</p>
          </div>
        </div>
        <div className="row">
          <img src={computer.default} alt="computer"/>
          <div className="text">
            <h3>Design & UX</h3>
            <p>I have been designing for print and digital media for more than 15 years, from websites to logos and letterheads, I love creating beautiful, functional, user-focused designs.</p>
            <p className="desktop-only">I fervently believe good design is the cornerstone of communicating effectively on the internet.</p>
          </div>
        </div>
        <div className="row">
          <img src={animation.default} alt="animation"/>
          <div className="text">
            <h3>Animation</h3> 
            <p>I've loved all forms of animation my whole life. Animation when done well can weave a story in ways that static images just can't.</p>
            <p className="desktop-only">I love including movement in websites to enhance UX and to create delight.</p>
          </div>
        </div>
        <div className="row">
          <img src={camera.default} alt="camera"/>
          <div className="text">
            <h3>Photography</h3>
            <p>I love taking photographs, I'm a bit of a hobby photographer, but I'm well versed in taking professional photos both in and out of the studio.</p>
          </div>
        </div>
        <div className="row">
          <img src={notebook.default} alt="notebook"/>
          <div className="text">
            <h3>Copywriting</h3>
            <p>I am a passionate writer, and can write exceptional copy for your business. I love to write short stories in my spare time.</p>
          </div>
        </div>
      </div>
    )
  }
};

export default SkillSpinner;