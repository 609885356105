import React from 'react';
import './App.scss';
import { Hero, WhoIAm, WhatIDo, Skills, Brands, SayHi } from './Homepage/Homepage'

function App() {
  return (
    <div className="App">
      <div className="App-LHS">
        <Hero></Hero>
      </div>
      <div className="App-RHS">
        <WhoIAm></WhoIAm>
        <WhatIDo></WhatIDo>
        <Skills></Skills>
        <Brands></Brands>
        <SayHi></SayHi>
      </div>
    </div>
  );
}

export default App;
