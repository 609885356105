import React from 'react';
import './Homepage.scss';
// import { angleBottom, angleTop } from '../images';
// import { abc, acc, aidacare, businest, envisage, livewire, oneBigSwitch, petRescue, pwc, qantas, reachout, reinteractive, scottishPacific, westfield } from '../images/logos'

const BrandLogos = () => {
  return (
    <div id="BrandLogos">
      <div className="brand qantas" />
      <div className="brand pwc" />
      <div className="brand westfield" />
      <div className="brand abc" />
      <div className="brand ticketek" />
      <div className="brand reachOut" />
      <div className="brand aidacare" />
      <div className="brand oneBigSwitch" />
      <div className="brand businest" />
      <div className="brand envisage" />
      <div className="brand livewire" />
      <div className="brand acc" />
      <div className="brand petRescue" />
      <div className="brand reinteractive" />
      <div className="brand scottishPacific" />
      
    </div>
  )
}

export default BrandLogos